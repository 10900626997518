var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.secrecyVisible ? _c("CommonDialog", {
    attrs: {
      title: "温馨提示",
      "cancel-text": "取消",
      "btn-text": "确认",
      "style-object": {
        background: "rgba(0, 0, 0, 1)"
      }
    },
    on: {
      close: _vm.onCloseDialog,
      click: _vm.onConfirmClose
    }
  }, [_c("template", {
    slot: "content"
  }, [_c("section", {
    staticClass: "secrecy-con"
  }, [_vm._v(" " + _vm._s(_vm.secrecyInfo) + " ")])])], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };