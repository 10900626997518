import Storage from '@/utils/storage';
import CommonDialog from '@/components/common/CommonDialog';
export default {
  name: 'PayslipSecrecy',
  components: {
    CommonDialog
  },
  props: {
    targetPath: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isFirst: Storage.localGet('isFirst') || 'true',
      // 是否第一次进入
      secrecyVisible: true,
      secrecyInfo: '薪资为保密信息，请勿外传。'
    };
  },
  watch: {
    secrecyVisible() {
      this.$emit('toggleSecrecyVisible', this.isFirst);
    }
  },
  created() {
    this.handleDialog();
  },
  methods: {
    handleDialog() {
      if (this.isFirst === 'true') {
        // 首次 出弹窗
        this.secrecyVisible = true;
      } else {
        // 不是首次 隐藏弹窗，显示数据
        this.secrecyVisible = false;
      }
    },
    onCloseDialog() {
      this.secrecyVisible = false;
    },
    onConfirmClose() {
      this.isFirst = 'false';
      Storage.localSet('isFirst', 'false');
      this.secrecyVisible = false;
    }
  }
};