var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "prompt-dialog"
  }, [_c("div", {
    staticClass: "mask",
    style: _vm.styleObject,
    on: {
      touchmove: function ($event) {
        $event.preventDefault();
      }
    }
  }), _c("div", {
    staticClass: "main"
  }, [_vm.showClose ? _c("i", {
    staticClass: "close-con",
    on: {
      click: _vm.onClose
    }
  }, [_c("svg", {
    attrs: {
      width: "18",
      height: "18",
      viewBox: "0 0 21 21",
      fill: "none",
      stroke: "#7BAFED",
      "stroke-width": "1",
      xmlns: "http://www.w3.org/2000/svg"
    }
  }, [_c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M19.4444 0L21 1.55556L1.55556 21L0 19.4444L19.4444 0Z",
      fill: "#7BAFED"
    }
  }), _c("path", {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M1.55556 0L0 1.55556L19.4444 21L21 19.4444L1.55556 0Z",
      fill: "#7BAFED"
    }
  })])]) : _vm._e(), _vm.title ? _c("h2", [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c("div", {
    staticClass: "content"
  }, [_vm._t("content")], 2), _c("footer", {
    class: _vm.cancelText ? "multiple-child" : "only-child"
  }, [_vm.cancelText ? _c("button", {
    staticClass: "cancel-btn",
    on: {
      click: _vm.onCancel
    }
  }, [_vm._v(" " + _vm._s(_vm.cancelText) + " ")]) : _vm._e(), _c("button", {
    on: {
      click: _vm.onSubmit
    }
  }, [_vm._v(_vm._s(_vm.btnText || "确认"))])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };