export default {
  name: 'CommonDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    btnText: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: ''
    },
    styleObject: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    showClose() {
      return this.show;
    }
  },
  watch: {
    showClose(val) {
      if (val) {
        this.stopMoveFn();
      } else {
        this.moveFn();
      }
    }
  },
  methods: {
    // 禁止滚动
    stopMoveFn() {
      const mo = e => {
        e.preventDefault();
      };
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', mo, false); // 禁止页面滑动
    },
    // 取消滑动限制
    moveFn() {
      const mo = e => {
        e.preventDefault();
      };
      document.body.style.overflow = ''; // 出现滚动条
      document.removeEventListener('touchmove', mo, false);
    },
    onCancel() {
      this.$emit('close');
    },
    onSubmit() {
      this.$emit('click');
    },
    onClose() {
      this.$emit('close');
    }
  }
};